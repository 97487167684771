.Intro-content {
    color: white;
    margin-bottom: 3em;
    background: transparent;
    background-color: rgb(41, 6, 57);
}

.carroussel-contener {
    display: flex;
    justify-content: center;
    background: linear-gradient(rgb(0, 0, 0), rgb(25, 12, 29), rgb(95, 6, 120), rgb(19, 3, 13));



    .carroussel-content {
        background-color: linear-gradient(rgb(0, 0, 0), rgb(25, 12, 29), rgb(95, 6, 120), rgb(19, 3, 13));

        padding: 20px 30px 0 30px;
        width: 50%;
        object-fit: cover;

    }
}

.image-detail {
    position: relative;
    overflow: hidden;
    width: 100%;
    // height: 60vh;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    #imag {
        height: 50%;
        object-fit: cover;

        img {
            width: 100%;
            height: 70%;
            object-fit: cover;
        }
    }



    .detail-img-card {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        .detail-img {
            margin-top: 30%;
            padding: 2.5%;
        }

    }
}

// .slide-point {
//     a {
//         display: inline-block;
//         margin: 10px;
//         text-indent: -999px;
//         border-radius: 7px;
//         width: 7px;
//         height: 7px;
//         background-color: aliceblue;
//         opacity: .5;
//     }
// }
