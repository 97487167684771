@import "/src/mixins";
@import "/src/variables";

section {
  #store-contain {
    color: $secondary_color;
    @include flexCenter;
    padding: 1em;

    #store-grid {
      max-width: 1200px;
    
      #store-category{
        #mk-heading{
          display: flex
          flex
        }
      }
      #store-product {
        @include flexAround;
        flex-wrap: wrap;
        gap: 0.8em;

        #store-card {
          border: 2px solid $mauve;
          padding: 0.5em;
          transition: transform 0.5s;

          #store-pic {
            width: 240px;
            height: 200px;

            img {
              @include widthHeightFull;
              object-fit: cover;
            }
          }

          #store-info {
            text-align: center;
          }

          &:hover {
            transform: translateY(-10px);
            background-color: #522e54;
            color: #e6e6e6;
            // box-shadow: 4px 4px 2px 0px rgba(82,46,84,0.63);
          }
        }
      }
    }
  }
}

.see-more {
  @include linkFullBlack;
  padding: 0.7em 1em;
}

#paginate {
  border: 2px solid $secondary_color;
  padding: 0.5em 1em;
  // margin-right: 0.5em;
  transition: transform 0.3s;
}

#paginate:hover {
  transform: scale(0.9);
}

.active {
  background-color: $secondary_color;
  color: $principal_color;
}



// ********************Responsive********************


@media screen and (max-width: 514px){
  section {
    #store-contain {
       
      #store-grid {
        #store-category{
          #mk-heading{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            #filter-input{
              width: 100%;
              margin: 0.5em .75em;
            }
          }
        }
      }
    }
  }
}