@import "/src/mixins";
@import "/src//variables";

section {
    #payment-form {
        #payment-contain {
            margin: 0 auto;
            max-width: 400px;
            color: $secondary_color;
            padding: 1em;

            form {
                button {
                    @include linkFullBlack;
                    padding: 0.5em 2.5em;
                }
            }
        }
    }
}