@import "/src/variables";
@import "/src/mixins";

section {
  #hero-contain {
    color: $principal_color;
    padding-left: 9em;

    #hero-flex {
      @include flexBetween;

      #hero-search {
        @include flexColumn;
        justify-content: center;
        gap: 2em;
        max-width: 800px;

        #hero-title {
          h1 {
            font-size: 3em;
            font-weight: bold;
            margin-bottom: 1.5em;
          }

          h2 {
            font-size: $h1_size;
          }
        }

        #hero-input {
          button {
            background-color: $mauve;
            border: 1px solid $mauve;
          }
          button:hover {
            background-color: #572373;
            border: 1px solid #572373;
          }
        }
        #cate {
          #nt-gen {
            background-color: #572373;
            color: $principal_color;
          }
          .link-cate {
            background-color: $principal_color;
            color: $secondary-color;
            padding: 0.4em 1.4em;
            border-radius: 50px;
            font-weight: 600;
            transition: background-color 0.3s;
            &:hover {
              background-color: $mauve;
              color: $principal_color;
            }
          }
          .link-coll {
            padding: 0em 1.4em;
            background-color: $principal_color;
            border-radius: 50px;
            color: $secondary-color;
            line-height: 1.2em;
            font-weight: 600;
            &:hover {
              background-color: $mauve;
              color: $principal_color;
            }
          }
        }

        #hero-btn {
          @include flex;

          .hero-btn-search {
            @include linkWhiteBorder;
          }

          .hero-btn-sell {
            @include linkFullWhite;
            padding: 1.45em 3.5em;
          }
        }
      }

      #superman-container {
        @include widthMiddle;

        .superman {
          @include widthFull;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
#keyword:focus {
  outline: none;
}

#search {
  transition: background-color 0.4s ease;
}

#search:hover {
  background-color: rgb(219, 218, 218);
}

/******************************Responsive mode****************************/

@media screen and (max-width: 880px) {
  section {
    #hero-contain {
      margin-top: 3em;
      padding: 0;

      #hero-flex {
        justify-content: space-evenly;
        #superman-container {
          display: none;
        }

        #hero-search {
          margin: 0 auto;
          justify-content: center;
          #hero-title {
            h1 {
              font-size: 1.5em;
              text-align: center;
            }
            h2 {
              font-size: 1.2em;
              padding-left: .4em;
            }
          }
          #hero-input {
            margin: 0 0 3em .4em;
            .input-container {
              position: relative;
              display: inline-block;

              input {
                padding: 0.3em 15px;
                border-radius: 27px;
              }

              svg {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
              }
            }
          }
          #hero-btn {
            margin: 1em 0;
            .hero-btn-search,
            .hero-btn-sell {
              padding: 0.25em 1.75em;
            }
            .hero-btn-sell {
              @include flexAlignCenter;
              justify-content: center;
            }
          }
        
          #cate,#text-cate{
            padding-left: .4em;
          }
        }
      }
    }
  }
}
