$font_path: "./css/fonts/";
$principal_color: #fefefe;
$secondary_color: #010101;
$mauve : #6d318e;

@font-face {
  font-family: "Articulat";
  src: url("#{$font_path}ArticulatCF-Normal.otf") format("opentype");
}

$h1_size: 1.875em;
$h2_size: 1.563em;
$h3_size: 1.25em;
$h4_size: 0.938em;
$h2_mini : 1.3em;