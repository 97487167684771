@import "/src/mixins";
@import "/src//variables";

footer {
  padding: 1em;
  font-family: "Articulat", Arial, sans-serif;
  color: $principal_color;
  position: relative;

  video {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    @include widthHeightFull;
    z-index: -1;
  }

  //   background-color: black;

  #footer-contain {
    padding: 2em;
    margin: auto;

    #footer-flex {
      #footer-newsletter {
        @include flexAlignCenter;
        @include justifyCenter;
        gap: 1em;
        margin: auto;
        margin-bottom: 4em;
        flex-wrap: wrap;
        #newsletter-title{
          width: auto;
          text-align: center;
        }
        #footer-input {
          .footer-input-container {
            position: relative;
            display: inline-block;

            input {
              padding: 0.6em 30px;
              border-radius: 27px;
            }

            svg {
              @include inputIcon;
            }
          }

          #newsletter-subscribe {
            margin-left: 1em;
            padding: 0.5em 2.3em;
          }
        }
      }

      #footer-list {
        @include flexEvenly;
        padding: 0 4em;

        .footer-title {
          .footer-h4 {
            padding: 0.5em 0;
            text-align: center;
            position: relative;
          }

          .footer-h4::before {
            content: "";
            position: absolute;
            @include widthFull;
            height: 1px;
            background-color: $principal_color;
            bottom: 0;
            left: 0;
          }

          div {
            margin-top: 1em;
            text-align: left;
            p {
              padding: 0.5em 0;
            }
          }
        }
      }
    }
  }
}

/******************************Responsive mode****************************/

@media screen and (max-width: 820px) {
  footer {
    #footer-contain {
      padding: 0;

      #footer-flex {
        #footer-newsletter {
          margin: auto;
          max-width: 774px;
          padding: 1em;
          flex-wrap: wrap;
          justify-content: center;
          gap: 1em;
          h4{
            width: 100%;
            text-align: center;
          }
          #footer-input {
            .footer-input-container {
              input {
                padding: 0.3em 15px;
                margin: 1em 0;
              }
            }

            #newsletter-subscribe {
              margin-left: 0.4em;
              padding: 0.3em 1em;
            }
          }
        }

        #footer-list {
          padding: 0;
          //flex-direction: column;
          gap: 1em;
          p {
            font-size: small;
            text-align: left;
          }
          .footer-title{
            .footer-h4{
              align-items: start;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 487px) {
  footer {
    #footer-contain {
      padding: 0;

      #footer-flex {
        #footer-newsletter {
          padding: 1em;
          flex-wrap: wrap;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 1em;
          h4{
            width: 100%;
            text-align: center;
          }
          #footer-input {
            display: grid;
            .footer-input-container {
              input {
                padding: 0.3em 15px;
                margin: 1em 0;
              }
            }

            #newsletter-subscribe {
              margin-left: 0.4em;
              padding: 0.3em 1em;
            }
          }
        }

        #footer-list {
          padding: 0;
          flex-direction: column;
          gap: 1em;
          p {
            font-size: small;
            text-align: left;
          }
          .footer-title{
            .footer-h4{
              width: fit-content;
              align-items: start;
              text-align: left;
            }
          }
        }
      }
    }
  }
}