@import "/src/mixins";
@import "/src/variables";

section {
  #profil-contain {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4em 1em;

    #profil-grid {
      display: grid;
      grid-gap: 1.5em;
      grid-template-columns: 300px 1fr;

      #profil-info {
        #avatar {
          @include columnAlignCenter;

          h2 {
            font-size: $h2_size;
            font-weight: bold;
          }

          h4 {
            font-size: $h2_mini;
          }

          img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            border-radius: 50%;
          }

          #credit {
            background-color: $principal_color;
            color: $mauve;
            padding: 0.7em 2.5em;
            font-size: larger;
            margin-bottom: 1em;
          }

          
        }

        #dashbord {
          @include dashbord;

          div {
            @include flexBetween;
            align-items: center;
            @include dashactu;

            img {
              @include dashboardIcon;
            }
          }
        }
      }

      #profil-fav {
        background-color: $principal_color;
        height: 100%;
        border-radius: 27px;
        padding: 1em;
      }
    }
  }
}

.mb {
  border-bottom: $mauve 1px solid;
}
.mb:hover{
  // background-color: red;
  // background-color: ;
}

/*********************************/


@media screen and (max-width: 820px) {
  section{
    #profil-contain{
      #profil-grid{
        display: flex;
        flex-direction: column;
        justify-items: center;
      }
    }
  }
}