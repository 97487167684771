.mainbod {
    background: linear-gradient(rgb(0, 0, 0), rgb(25, 12, 29), rgb(95, 6, 120), rgb(19, 3, 13));

    .bod {
        color: rgb(23, 15, 15);
        margin: 0 5% 0 5%;

        .container {
            padding: 2% 10% 2% 10%;

            .content {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                /* Crée deux colonnes de taille égale */
                gap: 50px;
                /* Espacement entre les éléments de la grille */

                .img-actu {
                    .imag {
                        height: 65%;
                        width: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            
            }

        }
    }
    .dateCommen {
        display: flex;
        justify-content: space-between;
    }

    .viewMore {
        display: flex;
        justify-content: center;
        margin: 20px 30px 10px 30px;
        font-size: 20px;
        button {
            padding: 10px 40px 10px 40px;
            background-color:  rgb(35, 16, 41);
            color: aliceblue;
            transition: 0.5s;
        }

        button:hover {
            background-color:  rgb(98, 50, 98);
        }
    }
}