@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .appear {
    animation: opacity 0.5s ease;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .bg-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(44, 8, 102, 0.5);
    z-index: 14999;
  }

  .modal-content {
    max-width: 80%;
    max-height: 80%;
    background: aliceblue;
    padding: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 19999;
  }

  .modal-content img {
    max-width: 100%;
    max-height: 80vh;
    /* Ajustez la hauteur maximale en fonction de vos besoins */
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .text-red {
    color: red
  }

}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #CDC5DD;
}

body::-webkit-scrollbar-thumb {
  background-color: #6d318e;
  border-radius: 20px;
  border: 2px solid #CDC5DD;
}