@import "/src/mixins";
@import "/src/variables";

section {
  #vitrine-contain {
    padding: 4em;

    #vitrine-flex {
      max-width: 1200px;
      margin: 0 auto;
      background-color: #f3e8ff;
      padding: 2em;
      border-radius: 17px;

      h1 {
        font-size: $h1_size;
      }
      #store-card {
        border: 2px solid $mauve;
        padding: 0.5em;
        margin: 1em 0;
        transition: transform 0.5s;
        &:hover {
            transform: translateY(-10px);
            background-color: #522E54;
            color: #e6e6e6;
          }
      }
    }
  }
}
