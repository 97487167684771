@import "/src/mixins";
@import "/src/variables";

section {
  #profil-contain {
    max-width: 1000px;
    margin: 0 auto;
    #profil-fav {
      background-color: aliceblue;
    }
  }
  #fav-card {
    border: 2px solid $mauve;
    padding: 0.5em;
    transition: transform 0.5s;

    &:hover {
      transform: translateY(-10px);
      background-color: #522e54;
      color: #e6e6e6;
    }
  }
}
.retirer,#delpanier {
  background-color: #ef4444;
  color: $principal_color;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: $principal_color;
    color: #ef4444;
  }
}
#delpanier{
  padding: 0.5rem;
  
}
#pay{
  background-color: rgb(36, 188, 36);
  color: $principal_color;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: $principal_color;
    color:  rgb(36, 188, 36);
  }
}

@media screen and (max-width: 484px) {
  .retirer {
    padding: 0.16rem;
  }
}
