@mixin flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin columnAlignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin flex {
  display: flex;
}

@mixin flexBetween {
  display: flex;
  justify-content: space-between;
}

@mixin flexAround {
  display: flex;
  justify-content: space-around;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
}

@mixin flexAlignCenter {
  display: flex;
  align-items: center;
}

@mixin spaceEvenly {
  justify-content: space-evenly;
}

@mixin flexEvenly {
  display: flex;
  justify-content: space-evenly;
}

@mixin justifyCenter {
  justify-content: center;
}

@mixin spaceAround {
  justify-content: space-around;
}

@mixin spaceBetween {
  justify-content: space-between;
}

@mixin alignCenter {
  align-items: center;
}

@mixin widthMiddle {
  width: 50%;
}

@mixin widthFull {
  width: 100%;
}

@mixin widthHeightFull {
  width: 100%;
  height: 100%;
}

@mixin linkWhiteBorder {
  border: 3px solid white;
  padding: 0.5em 3.5em;
  font-weight: bolder;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: white;
    color: black;
  }
}

@mixin linkBlackBorder {
  border: 3px solid black;
  padding: 0.5em 3.5em;
  font-weight: bolder;
  text-decoration: none;
  color: black;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: black;
    color: white;
  }
}

@mixin linkFullWhite {
  background-color: white;
  padding: 0.5em 3.5em;
  font-weight: bolder;
  text-decoration: none;
  color: black;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: black;
    color: white;
  }
}

@mixin linkFullBlack {
  background-color: black;
  padding: 0.8em 1em;
  font-weight: bolder;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgb(217, 214, 214);
    color: black;
  }
}

@mixin formContain {
  max-width: 600px;
  height: 500px;
  margin: 0 auto;
  background-color: white;
  border-radius: 27px;
}

@mixin inputForm {
  padding: 0.4em 30px 0.4em 0.4em;
  border-radius: 27px;
  border: 2px solid black;
}

@mixin inputIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

@mixin dashbord {
  background-color: $principal_color;
  color: $mauve;
  border-radius: 27px;
}

@mixin dashactu {
  font-size: larger;
  padding: 1em 0 1em 0.5em;
}

@mixin dashboardIcon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
}