@import "/src/mixins";
@import "/src/variables";

section {
  #mention-bloc {
    padding: 2em 0;
    #mention-container {
      max-width: 1200px;
      margin: 0 auto;
      h1 {
        font-size: 2em;
        text-align: center;
        font-weight: bold;
        margin-bottom: 1em;
        text-decoration: underline;
      }
      h2 {
        margin-top: 1em;
        font-size: 1.5em;
        font-weight: bold;
        text-decoration: underline;
      }
      p {
        font-size: large;
        font-weight: 550;
      }
    }
  }
}
