@import "/src/variables";
#btn-sroll {
  #cercle {
    position: fixed;
    width: 100%;
    left: 40%;
    bottom: 40%;
    z-index: 1;
    color: $mauve;
    font-size: 2rem;
  }
}
