@import "/src/mixins";
@import "/src/variables";

section {
  #login-contain {
    padding: 4em;
    .login-form {
      @include formContain;
      #login-flex {
        @include columnAlignCenter;
        @include spaceAround;
        height: 100%;
        h1 {
          font-size: $h1_size;
          font-weight: bold;
        }
        .login-input {
          .login-input-container {
            position: relative;
            display: inline-block;

            input {
              width: 100%;
              max-width: 100%;
              padding: 0.4em 30px 0.4em 0.4em;
              border-radius: 27px;
              border: 2px solid black;
              box-sizing: border-box;
            }

            svg {
              @include inputIcon;
            }
          }
        }
        #login-btn-container {
          #login-btn {
            @include linkFullBlack;
          }
        }
      }
    }
  }
}


/******************************Responsive mode****************************/

@media screen and (max-width: 405px) {

  section {
    #login-contain {
      .login-form {
        #login-flex {
          h1 {
            font-size: $h2_mini;
            font-weight: bold;
          }
          #login-btn-container {
            #login-btn {
              @include linkFullBlack;
              padding: 0.5em 0.3em;
            }
          }
        }
        #reset-sub{
          display: flex;
          align-items: center;
        }
      }
    }
  }
}