@import "/src/variables";
@import "/src/mixins";

section {
  font-family: "Articulat", sans-serif;
  #magasine-contain {
    color: $principal_color;
    h2 {
      font-size: $h2_size;
      margin-bottom: 1.5em;
    }
    h3 {
      font-size: $h3_size;
    }
    #magasine-grid {
      margin-bottom: 5em;
      max-width: 1200px;
      display: grid;
      align-items: center;
      gap: 10em;
      grid-template-columns: 350px 1fr;

      #dashbord {
        @include dashbord;
        div {
          @include dashactu;
          @include flexBetween;
          align-items: center;
          img {
            @include dashboardIcon;
          }
        }
      }
      #magasine-actu {
        gap: 1em;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        #actu-jour {
          font-size: $h2_size;
          height: 300px;
          grid-column: 1/4;
        }
        div {
          img {
            height: 90%;
            @include widthFull;
            object-fit: cover;
          }
        }
        .mini-actu {
          height: 250px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
}

.mb{
  border-bottom: $mauve 1px solid;
}

@media screen and (max-width: 820px) {
  section{
    //margin-left: -.3em;
    #magasine-contain{
      padding-left: .8em;
      padding-right: .5em;
      justify-content: center;
      #magasine-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
      }
      #magasine-title{
        max-width: 590px;
        margin: auto;
      }
    }
  }
  #dashbord{
    width: 100%;
    max-width: 578px;
    border-radius: 10px;
    color: #ffffff80

  }
}