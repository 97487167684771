@import "/src/mixins";
@import "/src/variables";

section {
 
  #market-container{
    margin: auto;
    max-width: 1200px;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: stretch;
  }
  .market-all {
    background-color: #e6e6e6;
    color: $mauve;
    font-size: $h2_size;
    font-weight: bold;
    position: relative;
    @include flexCenter;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease;


    h3:hover {
      text-decoration: underline;
    }


    a {
      position: absolute;
      @include widthHeightFull;
    }
  }

  .market-all:hover {
    background-color: #522E54;
    color: #e6e6e6;
    filter: drop-shadow(10px 10px 10px $mauve) invert(2%)
  }

  #market-grid{
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  #tapis {
    width: 100%;
    grid-column: 5/1;
    height: 250px;
    object-fit: contain;
    @include flexCenter;
    align-items: center;

    a {
      @include linkWhiteBorder;
    }
  }
}


.market-card {
  transition: transform .5s;
  border: 2px solid $mauve;

  &:hover {
    transform: translateY(-10px);
    background-color: #522E54;
    color: #e6e6e6;
    // box-shadow: 4px 4px 2px 0px rgba(82,46,84,0.63);
  }
}

//  @media screen and (max-width: 820px) {
//    section {
//      #market-container {
//        #market-grid {
//          display: flex;
//          flex-wrap: wrap;
//        }
//      }
//    }
//  }