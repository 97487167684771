@import "/src/variables";
@import "/src/mixins";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

#headerHome {
  color: $principal_color;
  #contain {
    // padding: 1em 0;
    position: relative;

    #flexable {
      @include flexAround;
      @include alignCenter;
      #log-sub-menu {
        display: none;
      }

      a {
        img {
          width: 18.7em;
          // width: 300px;
          cursor: pointer;
        }
      }

      #burger-menu {
        display: none;
      }

      ul {
        @include flex;

        li {
          .nav-link {
            color: $principal_color;
            padding: 1em;
          }
        }
      }

      #shop {
        @include flexAlignCenter;

        #basket {
          margin-right: 1.6em;
          @include flexAlignCenter;

          .basket-pic {
            width: 25px;
            margin-right: 0.5em;
          }

          #shopping-text {
            font-size: small;
          }
        }

        #login-link {
          @include linkFullWhite;
          padding: 0.5em;
        }
      }
    }
    #sub-menu {
      display: none;
    }
    .isVisible {
      visibility: hidden;
      transition: visibility .2s ease-out;
    }
  }
}

/******************************Responsive mode****************************/

@media screen and (max-width: 820px) {
  #headerHome {
    position: relative;
    // top: 0;
    // left: 0;
    z-index: 1;
    width: 100%;
    #contain {
      left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
      #flexable {
        @include flexBetween;
        padding: 1em;
        height: 8em;


        #log-sub-menu {
          display: block;
          @include flexAlignCenter;

          .icon-sub-menu {
            font-size: 1.8em;
          }
          .sub-menu-basket {
            width: 1.8em;
            object-fit: cover;
          }
        }

        #nav-pic {
          @include flexAlignCenter;

          img {
            width: 9em;
            object-fit: cover;

          }

          #burger-menu {
            font-size: x-large;
            cursor: pointer;
          }
        }

        #burger-menu {
          @include flex;
        }

        #shop,
        ul {
          display: none;
        }
      }

      #sub-menu {
        position: absolute;
        height: 100vh;
        z-index: 1;
        @include widthFull;
        background-color: #290c39;
        @include flexColumn;

        .sub-menu-link {
          color: $principal_color;
          padding: 1em 0.4em;
          font-weight: bold;
          border-bottom: 1px solid $principal_color;
        }
      }
    }
  }
}
