@import "/src/mixins";
@import "/src/variables";

#filter-input{
    #searchbar{
        box-shadow: 0px 0px 10px 0 #a2a2a25e;
        transition: 100ms;
    }
    :hover{
        border-color: #8947ad;
    }
    :focus{
        outline: 2px $mauve solid;
        outline-offset: 1px;
    }
}