@import "/src/mixins";
@import "/src/variables";

section {
  #get-annonce-contain {
    #get-annonce-size {
      max-width: 1200px;
      margin: 0 auto;

      h1 {
        font-size: $h1_size;
        font-weight: bold;
      }

      #get-annonce-flex {
        #get-annonce-info {
          max-width: 500px;
          background-color: #e6e6e6;
          padding: 1em;

          h2,
          p {
            font-size: $h2_size;
          }

          h3 {
            font-size: $h2_size;
          }

          #sell-by {
            color: $mauve;

            img {
              width: 75px;
              height: 75px;
              object-fit: cover;
              border-radius: 100%;
            }
          }

          #get-annonce-action {
            text-align: center;
            @include flexColumn;
            gap: 15px;

            .action {
              background-color: $mauve;
              color: $principal_color;
              padding: 0.7em 1.7em;
              border-radius: 27px;
              transition: background-color .3s;

              &:hover {
                background-color: #361248;
              }
            }
          }
        }
      }

      #get-annonce-desc {
        h3 {
          font-size: $h3_size;
        }

        background-color: #E6E6E6;
        padding: 2em;
      }
    }
  }
}


/*******************************************************/


@media screen and (max-width: 563px) {
  #get-annonce-flex{
    display: block;
  }
  #get-annonce-desc,#get-annonce-info{
    margin: .5em;
  }
  .annonce-pic {
    width: 80%;
    margin: 0 auto 0.5em auto;
  }
}