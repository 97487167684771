@import "/src/mixins";
@import "/src/variables";

#filter-container{

    #filter-list{
        display: flex;
        flex-wrap: wrap;
        gap: 0.75em;
        .filter-list-item{
            background-color: #fff;
            border-radius: 50px;
            padding: 0.4em 1.4em;
            font-weight: 600;
            transition: 200ms ease-out;

        }
        :hover{
            background-color: #8947ad;
            color: #fff;
        }
    }
    #filter-list .active{
        color: #fff;
        background-color: $mauve;
        box-shadow: 0px 0px 4px 0px#8947ad;
    }
}